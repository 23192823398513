import { makeStyles, Typography } from "@material-ui/core";
import { useAtom } from "jotai";
import { siteDataAtom } from "../atoms";
import GitHubIcon from "@material-ui/icons/GitHub";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "max(10vh,100px)",
    width: "100%",
    backgroundColor: "#0E0B16",
  },
  footer: {
    color: "white",
    textAlign: "center",
  },
  icon: {
    color: "white",
    marginTop: 5,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [siteData] = useAtom(siteDataAtom);

  return (
    <section className={classes.root}>
      <div className={classes.footer}>
        <div>
          <IconButton
            aria-label="github"
            className={classes.icon}
            size="medium"
            onClick={() => window.open(siteData.socials.github, "_blank")}
          >
            <GitHubIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="linkedin"
            className={classes.icon}
            size="medium"
            onClick={() => window.open(siteData.socials.linkedin, "_blank")}
          >
            <LinkedInIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="twitter"
            className={classes.icon}
            size="medium"
            onClick={() => window.open(siteData.socials.twitter, "_blank")}
          >
            <TwitterIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="telegram"
            className={classes.icon}
            size="medium"
            onClick={() => window.open(siteData.socials.telegram, "_blank")}
          >
            <TelegramIcon fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="email"
            className={classes.icon}
            size="medium"
            onClick={() =>
              window.open("mailto:" + siteData.socials.email, "_blank")
            }
          >
            <EmailIcon fontSize="large" />
          </IconButton>
        </div>
        <Typography variant="caption">Ben Wegert © 2021</Typography>
      </div>
    </section>
  );
};

export default Footer;
