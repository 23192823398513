import Flicking from "@egjs/react-flicking";
import { Hidden, makeStyles, Typography } from "@material-ui/core";
import { useAtom } from "jotai";
import { useState } from "react";
import { siteDataAtom } from "../atoms";
import Card from "../components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "max(100vh,870px)",
    width: "100%",
    backgroundColor: "white",
  },
  techHeader: {
    padding: 100,
    paddingBottom: 50,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: 10,
      paddingTop: 80,
      marginBottom: 50,
    },
  },
  flicker: {
    height: 300,
  },
  underline: {
    marginTop: 5,
    borderBottom: "solid 6px #A239CA",
    width: 280,
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      paddingTop: 5,
    },
  },
}));

const Technologies = () => {
  const classes = useStyles();
  const [siteData] = useAtom(siteDataAtom);
  const [selected, setSelected] = useState(siteData.tech.items[0].title);
  const [subList, setSubList] = useState(siteData.tech.items[0].items);

  const cardList = siteData.tech.items.map((item) => (
    <div key={item.title}>
      <Card
        clickable
        img={item.img}
        title={item.title}
        text={item.text}
        active={item.title === selected}
        onClick={() => {
          setSelected(item.title);
          setSubList(item.items);
        }}
      />
    </div>
  ));

  const subCardList = subList.map((item) => (
    <div key={item.title}>
      <Card img={item.img} title={item.title} text={item.text} height={60} />
    </div>
  ));

  return (
    <section className={classes.root}>
      <div className={classes.techHeader}>
        <Typography variant="h3">Technologies</Typography>
        <div className={classes.underline}></div>
      </div>
      <Hidden mdUp>
        <Flicking gap={20} zIndex={1} className={classes.flicker}>
          {cardList}
        </Flicking>
      </Hidden>
      <Hidden smDown>
        <Flicking gap={20} bound={true} zIndex={1} className={classes.flicker}>
          {cardList}
        </Flicking>
      </Hidden>
      <Hidden mdUp>
        <Flicking
          gap={20}
          zIndex={1}
          className={classes.flicker}
          adaptive={true}
        >
          {subCardList}
        </Flicking>
      </Hidden>
      <Hidden smDown>
        <Flicking
          gap={20}
          bound={true}
          zIndex={1}
          className={classes.flicker}
          adaptive={true}
        >
          {subCardList}
        </Flicking>
      </Hidden>
    </section>
  );
};

export default Technologies;
