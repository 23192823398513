import {
  Grid,
  Hidden,
  Button,
  makeStyles,
  Typography,
  Zoom,
} from "@material-ui/core";
import { useAtom } from "jotai";
import React from "react";
import { siteDataAtom } from "../atoms";
import Particles from "react-particles-js";
import { scroller } from "react-scroll";
import GitHubIcon from "@material-ui/icons/GitHub";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 570,
    height: "100%",
    width: "100%",
    position: "relative",
  },
  rootContent: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
  },
  heroContainer: {
    maxWidth: 1200,
    paddingLeft: 10,
    margin: "auto",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  profile: {
    width: 400,
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
  },
  h6: {
    marginTop: 15,
  },
  button: {
    marginTop: 30,
    marginBottom: 30,
    background: "linear-gradient(45deg, #A239CA 30%, #4717F6 90%)",
    border: 0,
    borderRadius: 25,
    boxShadow: "0 3px 5px 2px rgba(162,57,202, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  icon: {
    color: "white",
  },
  socials: {
    marginTop: 15,
  },
}));

const Welcome = () => {
  const classes = useStyles();
  const [siteData] = useAtom(siteDataAtom);

  return (
    <section className={classes.root}>
      <Particles
        style={{ height: "100%" }}
        params={{
          particles: {
            number: { value: 50, density: { enable: true, value_area: 800 } },
            color: { value: "#ffffff" },
            opacity: {
              value: 0.4,
              random: false,
              anim: { enable: true, speed: 1, opacity_min: 0.1, sync: false },
            },
            size: {
              value: 4,
              random: true,
              anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.3,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { enable: true, mode: "grab" },
              onclick: { enable: true, mode: "push" },
              resize: true,
            },
            modes: {
              grab: { distance: 200, line_linked: { opacity: 1 } },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: { distance: 200, duration: 0.4 },
              push: { particles_nb: 2 },
              remove: { particles_nb: 2 },
            },
          },
          retina_detect: false,
        }}
      />
      <div className={classes.rootContent}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.heroContainer}
        >
          <Hidden mdUp>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Zoom in={true}>
                <img
                  alt="profile"
                  src="/images/profile.png"
                  className={classes.profile}
                />
              </Zoom>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Hidden smDown>
              <Typography variant="h1" color="textPrimary">
                {siteData.welcome.h1}
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="h3" color="textPrimary">
                {siteData.welcome.h1}
              </Typography>
            </Hidden>

            <Typography variant="h6" color="textPrimary" className={classes.h6}>
              {siteData.welcome.h6}
            </Typography>
            <div className={classes.socials}>
              <IconButton
                aria-label="github"
                className={classes.icon}
                size="medium"
                onClick={() => window.open(siteData.socials.github, "_blank")}
              >
                <GitHubIcon fontSize="large" />
              </IconButton>
              <IconButton
                aria-label="linkedin"
                className={classes.icon}
                size="medium"
                onClick={() => window.open(siteData.socials.linkedin, "_blank")}
              >
                <LinkedInIcon fontSize="large" />
              </IconButton>
              <IconButton
                aria-label="twitter"
                className={classes.icon}
                size="medium"
                onClick={() => window.open(siteData.socials.twitter, "_blank")}
              >
                <TwitterIcon fontSize="large" />
              </IconButton>
              <IconButton
                aria-label="telegram"
                className={classes.icon}
                size="medium"
                onClick={() => window.open(siteData.socials.telegram, "_blank")}
              >
                <TelegramIcon fontSize="large" />
              </IconButton>
              <IconButton
                aria-label="email"
                className={classes.icon}
                size="medium"
                onClick={() =>
                  window.open("mailto:" + siteData.socials.email, "_blank")
                }
              >
                <EmailIcon fontSize="large" />
              </IconButton>
            </div>
            <Button
              className={classes.button}
              onClick={() =>
                scroller.scrollTo("projects", {
                  duration: 500,
                  smooth: true,
                })
              }
            >
              {siteData.welcome.actionButton}
            </Button>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Zoom in={true}>
                <img
                  alt="profile"
                  src="/images/profile.png"
                  className={classes.profile}
                />
              </Zoom>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </section>
  );
};

export default Welcome;
