import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import EmailIcon from "@material-ui/icons/Email";
import FolderIcon from "@material-ui/icons/Folder";
import InfoIcon from "@material-ui/icons/Info";
import MenuIcon from "@material-ui/icons/Menu";
import { scroller } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10,
  },
  backdrop: {
    height: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 2,
  },
}));

export default function SpeedDialTooltipOpen() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const actions = [
    {
      icon: <EmailIcon />,
      name: "Contact",
      func: () => {
        scroller.scrollTo("contact", {
          duration: 500,
          smooth: true,
        });
        setOpen(false);
      },
    },
    {
      icon: <FolderIcon />,
      name: "Projects",
      func: () => {
        scroller.scrollTo("projects", {
          duration: 500,
          smooth: true,
        });
        setOpen(false);
      },
    },
    {
      icon: <EmojiObjectsIcon />,
      name: "Tech",
      func: () => {
        scroller.scrollTo("technologies", {
          duration: 500,
          smooth: true,
        });
        setOpen(false);
      },
    },
    {
      icon: <InfoIcon />,
      name: "Welcome",
      func: () => {
        scroller.scrollTo("welcome", {
          duration: 500,
          smooth: true,
        });
        setOpen(false);
      },
    },
  ];

  return (
    <div className={classes.root}>
      <Backdrop open={open} className={classes.backdrop} />
      <SpeedDial
        ariaLabel="Mobile Dial"
        className={classes.speedDial}
        hidden={false}
        icon={<MenuIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.func}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
