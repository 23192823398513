import React from "react";
import Flicking from "@egjs/react-flicking";
import { Hidden, makeStyles, Typography } from "@material-ui/core";
import { useAtom } from "jotai";
import { siteDataAtom } from "../atoms";
import Card from "../components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "max(100vh,920px)",
    width: "100%",
    backgroundColor: "#0E0B16",
  },
  header: {
    color: "white",
    padding: 100,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: 10,
      paddingTop: 80,
      marginBottom: 50,
    },
  },
  flicker: {
    height: 500,
  },
  underline: {
    marginTop: 5,
    borderBottom: "solid 6px #4717F6",
    width: 174,
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      paddingTop: 5,
    },
  },
}));

const Projects = () => {
  const classes = useStyles();
  const [siteData] = useAtom(siteDataAtom);

  const cardList = siteData.projects.items.map((item) => (
    <div key={item.title}>
      <Card
        height={150}
        width={400}
        img={item.img}
        title={item.title}
        text={item.text}
        github={item.github}
        demo={item.demo}
        prod={item.prod}
        hashtags={item.hashtags}
        imageHeight={250}
      />
    </div>
  ));

  return (
    <section className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3">{siteData.projects.h3}</Typography>
        <div className={classes.underline}></div>
      </div>
      <Hidden mdUp>
        <Flicking gap={20} zIndex={1} className={classes.flicker}>
          {cardList}
        </Flicking>
      </Hidden>
      <Hidden smDown>
        <Flicking gap={20} bound={true} zIndex={1} className={classes.flicker}>
          {cardList}
        </Flicking>
      </Hidden>
    </section>
  );
};

export default Projects;
