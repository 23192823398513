import React from "react";

import Navigation from "../Navigation";
import Dial from "../Dial";
import Welcome from "../Welcome";
import Technologies from "../Technologies";
import Projects from "../Projects";
import Contact from "../Contact";
import Footer from "../Footer";

import { Element } from "react-scroll";

const Home = () => {
  return (
    <React.Fragment>
      <Navigation />
      <Dial />

      <Element name="welcome" />
      <Welcome />

      <Element name="technologies" />
      <Technologies />

      <Element name="projects" />
      <Projects />

      <Element name="contact" />
      <Contact />

      <Footer />
    </React.Fragment>
  );
};

export default Home;
