import "./styles/main.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import Home from "./components/pages/Home";
import axios from "axios";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { useAtom } from "jotai";
import { siteDataAtom } from "./atoms";

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#A239CA",
            secondary: "#0E0B16",
            alternative: "#4717F6",
            special: "#E7DFDD",
          },
          secondary: {
            main: "#0E0B16",
            secondary: "#A239CA",
            alternative: "#4717F6",
            special: "#0E0B16",
          },
          text: {
            primary: "#fff",
            secondary: "#0d161c",
            disabled: "#3a9efd",
            hint: "#f7a400",
          },
          background: {
            paper: "#E7DFDD",
            default: "#0d161c",
          },
        },
        typography: {
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
        overrides: {
          MuiFab: {
            primary: {
              backgroundColor: "#A239CA",
              "&:hover": {
                backgroundColor: "#A239CA",
              },
            },
          },
          MuiInputBase: {
            input: {
              color: "black",
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  const [siteData, setSiteData] = useAtom(siteDataAtom);
  useEffect(() => {
    getSiteData();
    //eslint-disable-next-line
  }, []);

  const getSiteData = async () => {
    axios
      .get("/siteData.json")
      .then((response) => {
        setSiteData(response.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Router>
      {siteData && (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route exact path="/" component={Home} />
            <Redirect to="/" />
          </Switch>
        </ThemeProvider>
      )}
    </Router>
  );
};

export default App;
