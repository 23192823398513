import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { siteDataAtom } from "../atoms";
import validate from "validator";
import { init, send } from "emailjs-com";
init("user_sMhtjUYjBAANVCQ3QFQYl");

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50%",
    width: "100%",
    background: "linear-gradient(45deg, #A239CA 30%, #4717F6 90%)",
  },
  header: {
    color: "white",
    padding: 100,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: 10,
      paddingTop: 80,
      marginBottom: 50,
    },
  },
  underline: {
    marginTop: 5,
    borderBottom: "solid 6px #4717F6",
    width: 274,
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      paddingTop: 5,
    },
  },
  form: {
    maxWidth: "min(600px,90vw)",
    margin: "auto",
  },
  textField: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.8)",
    borderRadius: 3,
  },
  button: {
    margin: 20,
    marginBottom: 50,
    width: 200,
    background: "linear-gradient(45deg, #4717F6 30%, #4717F6 90%)",
    border: 0,
    borderRadius: 25,
    boxShadow: "0 3px 5px 2px rgba(162,57,202, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  buttonField: {
    textAlign: "center",
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [siteData] = useAtom(siteDataAtom);
  const [values, setValues] = useState({ email: "", name: "", message: "" });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = () => {
    setError(false);
    if (
      validate.isEmail(values.email) &&
      values.name.length > 1 &&
      values.message.length > 10
    ) {
      send(siteData.email.service, siteData.email.template, values).then(
        (response) => {
          setValues({});
          setSuccess("Your message has been received");
        },
        (error) => {
          setError("Failed to send email. Please try again later");
        }
      );
    } else setError("Please fill out all fields");
  };

  return (
    <section className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3">{siteData.contact.h3}</Typography>
        <div className={classes.underline}></div>
      </div>
      <div className={classes.form}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.textField}
              required
              name="name"
              variant="filled"
              label="Name"
              value={values.name || ""}
              onChange={(e) =>
                setValues((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.textField}
              required
              value={values.email || ""}
              name="email"
              label="Email"
              variant="filled"
              onChange={(e) =>
                setValues((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              className={classes.textField}
              value={values.message || ""}
              label="Message"
              multiline
              variant="filled"
              rows={3}
              rowsMax={Infinity}
              onChange={(e) =>
                setValues((prev) => ({ ...prev, message: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonField}>
            {error && (
              <Typography variant="body2" style={{ color: "red" }}>
                {error}
              </Typography>
            )}
            {success && (
              <Typography variant="body2" style={{ color: "white" }}>
                {success}
              </Typography>
            )}
            <Button className={classes.button} onClick={submit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Contact;
