import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Badge, Button, Chip, Slide } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const MediaCard = ({
  img,
  title,
  text,
  active,
  github,
  demo,
  prod,
  hashtags,
  onClick,
  width,
  clickable,
  imageHeight,
  height,
  key,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: width || 270,
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    rootActive: {
      width: width || 270,
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
      border: "3px solid #A239CA",
    },
    media: {
      minHeight: imageHeight || 145,
    },
    hashtags: {
      marginTop: 10,
    },
    hashtag: {
      margin: 3,
    },
    button: {
      background: "linear-gradient(45deg, #4717F6 30%, #4717F6 90%)",
      color: "white",
      margin: 3,
    },
    githubButton: {
      margin: 3,
      background: "linear-gradient(45deg, #000 30%, #000 90%)",
      color: "white",
    },
    text: {
      [theme.breakpoints.up("md")]: {
        height: height || "unset",
      },
      marginBottom: 10,
    },
    notClickable: {
      cursor: "default",
    },
    clickable: {},
    live: {
      paddingRight: 10,
    },
    link: {
      textDecoration: "none",
    },
  }));

  const classes = useStyles();

  return (
    <Slide in={true}>
      <Card
        className={classes[active ? "rootActive" : "root"]}
        elevation={3}
        onClick={onClick}
      >
        <CardActionArea
          disableRipple={!clickable}
          className={clickable ? classes.clickable : classes.notClickable}
        >
          <CardMedia className={classes.media} image={img} title={title} />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.text}
            >
              {text}
            </Typography>
            {github && (
              <a
                className={classes.link}
                href={github}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className={classes.githubButton}
                  startIcon={<GitHubIcon />}
                >
                  Github
                </Button>
              </a>
            )}
            {demo && (
              <a
                className={classes.link}
                href={demo}
                target="_blank"
                rel="noreferrer"
              >
                <Button className={classes.button}>Demo</Button>
              </a>
            )}
            {prod && (
              <a
                className={classes.link}
                href={prod}
                target="_blank"
                rel="noreferrer"
              >
                <Button className={classes.button}>
                  <div className={classes.live}>Live</div>
                  <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    variant="dot"
                  />
                </Button>
              </a>
            )}
            {hashtags?.length > 0 && (
              <div className={classes.hashtags}>
                {hashtags.map((tag) => (
                  <Chip
                    variant="default"
                    color="primary"
                    label={`#${tag}`}
                    className={classes.hashtag}
                  />
                ))}
              </div>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Slide>
  );
};

export default MediaCard;
