import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import {
  AppBar,
  Button,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginRight: 0,
    marginLeft: "auto",
    marginTop: 5,
    marginBottom: 5,
  },
  navBar: {
    zIndex: 100000,
    position: "fixed",
    top: 0,
    height: 50,
    width: "100%",
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  specialButton: {
    width: 150,
    background: "linear-gradient(45deg, #A239CA 30%, #4717F6 90%)",
    border: 0,
    borderRadius: 25,
    boxShadow: "0 3px 5px 2px rgba(162,57,202, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  button: {
    marginRight: 20,
    padding: 15,
  },
}));

const Navigation = () => {
  const trigger = useScrollTrigger();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Slide appear={false} direction="down" in={!trigger}>
        <div className={classes.navBar}>
          <AppBar color="secondary">
            <Toolbar className={classes.toolbar}>
              <Link to="welcome" spy={true} smooth={true} duration={500}>
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.button}
                >
                  <Typography variant="button">Welcome</Typography>
                </Button>
              </Link>
              <Link to="technologies" spy={true} smooth={true} duration={500}>
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.button}
                >
                  <Typography variant="button">Technologies</Typography>
                </Button>
              </Link>

              <Link to="projects" spy={true} smooth={true} duration={500}>
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.button}
                >
                  <Typography variant="button">Projects</Typography>
                </Button>
              </Link>
              <Link to="contact" spy={true} smooth={true} duration={500}>
                <Button className={classes.specialButton}>
                  <Typography variant="button">Contact</Typography>
                </Button>
              </Link>
            </Toolbar>
          </AppBar>
        </div>
      </Slide>
    </React.Fragment>
  );
};

export default Navigation;
